.profile-action-modal .modal-dialog {
  max-width: 360px;
}
.profile-action-modal .modal-header {
  padding: 0 0 20px !important;
}
.profile-action-modal .modal-header .modal-title {
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #d61f79;
  font-size: calc(14px + var(--big-fs));
  line-height: 18px;
}
.profile-action-modal .modal-header .modal-title i {
  margin-right: 10px;
  font-size: calc(16px + var(--big-fs));
  line-height: 1;
}
.profile-action-modal .modal-header .modal-close {
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  color: #d61f79;
  font-size: calc(12px + var(--big-fs));
  line-height: 1;
}
.profile-action-modal .modal-content {
  border-radius: 4px;
  padding: 16px !important;
}
.profile-action-modal .modal-content form {
  display: grid;
  grid-gap: 10px;
  padding-top: 4px;
}
.profile-action-modal .modal-content form .form-check {
  margin-left: 2px;
  align-items: center;
}
.profile-action-modal .modal-content form .form-check .form-check-input {
  border-color: #d61f79;
}
.profile-action-modal .modal-content form .city-item-wr {
  display: flex;
  align-items: center;
  grid-gap: 4px;
  font-weight: bold;
  font-size: calc(14px + var(--big-fs));
  line-height: 18px;
}
.profile-action-modal .modal-content form .city-item-wr a,
.profile-action-modal .modal-content form .city-item-wr a i {
  font-size: calc(16px + var(--big-fs));
  line-height: 1;
}
.profile-action-modal .modal-content form .city-select {
  display: grid;
  grid-template-columns: 210px 1fr;
  width: 100%;
  grid-gap: 20px;
  align-items: center;
}
.profile-action-modal .modal-content form .city-select a {
  width: fit-content;
  font-weight: bold;
  text-transform: uppercase;
  font-size: calc(12px + var(--big-fs));
  line-height: 16px;
}
@media (max-width: 768px) {
  .profile-action-modal .modal-content form .city-select {
    grid-template-columns: 1fr auto;
  }
}
.profile-action-modal .modal-content form .input-wrapper {
  display: grid;
  position: relative;
}
.profile-action-modal .modal-content form .input-wrapper input,
.profile-action-modal .modal-content form .input-wrapper textarea {
  font-size: calc(14px + var(--big-fs));
  line-height: 18px;
}
.profile-action-modal .modal-content form .input-wrapper input {
  width: auto;
}
.profile-action-modal .modal-content form .input-wrapper input.with-icon {
  padding-right: 36px !important;
}
.profile-action-modal .modal-content form .input-wrapper textarea {
  resize: none;
  height: 60px;
}
.profile-action-modal .modal-content form .input-wrapper .clipboard {
  position: absolute;
  top: 0;
  right: 10px;
  display: flex;
  align-items: center;
  height: 34px;
}
.profile-action-modal .modal-content form .input-wrapper .clipboard i {
  cursor: pointer;
  color: #d61f79;
  font-size: calc(15px + var(--big-fs));
  line-height: 1;
}
.profile-action-modal .modal-content form .input-wrapper .error {
  display: block;
  min-height: 20px;
  color: #ff4869;
  padding: 0 12px;
  font-size: calc(12px + var(--big-fs));
  line-height: 16px;
}
.profile-action-modal .modal-content form .captcha-wrapper > div {
  display: flex;
  grid-gap: 12px;
}
.profile-action-modal .modal-content form .captcha-wrapper > div img {
  width: 92px;
  height: 34px;
}
.profile-action-modal .modal-content form .captcha-wrapper input {
  width: 130px;
}
.profile-action-modal .modal-body {
  padding: 0 !important;
}
.profile-action-modal .modal-body .feedback-submitted {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0 30px;
  color: #02b10e;
  word-break: break-word;
}
.profile-action-modal .modal-footer {
  padding: 30px 0 0 !important;
}
@media (max-width: 1024px) {
  .profile-action-modal .modal-content form .clipboard {
    height: 42px !important;
  }
  .profile-action-modal .modal-content form .captcha-wrapper > div img {
    width: 128px;
    height: 42px;
  }
  .profile-action-modal .modal-content form .captcha-wrapper > div input {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .profile-action-modal .modal-dialog {
    max-width: unset;
  }
}

main > .sc-container-fluid > .sc-container {
  margin-top: 20px;
}

.main-section {
  display: grid;
  grid-template-columns: 220px 1fr;
  grid-gap: 24px;
  margin-bottom: 30px;
}
.main-section .geo-list ul {
  margin: 10px 0 0;
  padding: 0;
}
.main-section .geo-list ul li {
  border-bottom: 1px solid #f0f0f0;
  font-weight: 600;
  font-size: calc(14px + var(--big-fs));
  line-height: 18px;
}
.main-section .geo-list ul li a {
  display: flex;
  align-items: center;
  padding: 5px;
}
.main-section .geo-list ul li a .sc {
  font-size: calc(24px + var(--big-fs));
  line-height: 1;
  margin-right: 4px;
}
.main-section .geo-list ul li a:hover {
  background-color: #f0f0f0;
}
@media (min-width: 1025px) {
  .main-section .geo-list > h1,
  .main-section .geo-list > span {
    padding: 0 5px;
  }
}
@media (max-width: 1024px) {
  .main-section .geo-list ul {
    margin-left: -16px;
    margin-right: -16px;
  }
  .main-section .geo-list ul li {
    font-size: calc(14px + var(--big-fs));
    line-height: 18px;
  }
  .main-section .geo-list ul li a {
    padding: 5px 12px;
  }
}
.main-section .banners {
  display: grid;
  grid-template-columns: 180px 1fr 180px;
  grid-gap: 8px;
}
.main-section .banners .day-box {
  height: 260px;
  border-radius: 4px;
  position: relative;
}
.main-section .banners .day-box .title-box {
  position: absolute;
  top: 9px;
  left: 9px;
  right: 9px;
  width: auto;
  padding: 2px 5px 0;
  border-radius: 2px;
  pointer-events: none;
}
.main-section .banners .day-box .title-box img {
  width: 31px;
  height: 24px;
  margin-right: 8px;
}
.main-section .banners .day-box .title-box span {
  font-size: calc(14px + var(--big-fs));
  line-height: 18px;
}
.main-section .banners .day-box .avatar {
  display: block;
  padding: 2px 2px 0;
  height: 210px;
}
.main-section .banners .day-box .avatar img {
  border-radius: 2px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.main-section .banners .day-box .content {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  grid-gap: 16px;
  padding: 3px 2px 3px 10px;
  height: 50px;
}
.main-section .banners .day-box .content .info {
  display: grid;
}
.main-section .banners .day-box .content .info a {
  width: fit-content;
}
.main-section .banners .day-box .content img {
  margin-right: 7px;
}
.main-section .banners .day-box.theme-1 {
  background-color: #ffd514;
}
.main-section .banners .day-box.theme-1 .info,
.main-section .banners .day-box.theme-1 a {
  color: #000000;
}
.main-section .banners .day-box.theme-1 .title-box {
  color: #000000;
  background-color: rgba(255, 213, 20, 0.8);
}
.main-section .banners .day-box.theme-2 {
  background-color: #d61f79;
}
.main-section .banners .day-box.theme-2 .info,
.main-section .banners .day-box.theme-2 a {
  color: #ffffff;
}
.main-section .banners .day-box.theme-2 .title-box {
  color: #ffffff;
  background-color: rgba(214, 31, 121, 0.8);
}
.main-section .banners .banner-box {
  border-radius: 4px;
  height: 260px;
  overflow: hidden;
  position: relative;
}
.main-section .banners .banner-box a {
  display: flex;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  animation: anime 6s infinite;
}
.main-section .banners .banner-box a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.main-section .banners .banner-small,
.main-section .banners .banner-large {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 10px;
  height: 260px;
  border-radius: 4px;
  overflow: hidden;
}
.main-section .banners .banner-small a,
.main-section .banners .banner-large a {
  display: flex;
  overflow: hidden;
}
.main-section .banners .banner-small a img,
.main-section .banners .banner-large a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.main-section .banners .banner-large {
  grid-template-rows: 1fr;
}
.main-section .banners .banner-large img {
  object-fit: cover;
}
.main-section .banners .agencies-list {
  grid-column: 1/-1;
}
.main-section .banners .agencies-list .carousel-inner {
  height: 298px;
}
.main-section .carousel-inner {
  padding: 10px;
  margin: -10px;
  width: auto;
}
.main-section .carousel-inner .carousel-item {
  height: 100%;
}
.main-section .agency-items-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
  height: 100%;
}
.main-section .agency-items-list .agency-item {
  display: grid;
  grid-template-columns: 130px 1fr;
  grid-gap: 8px;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  padding: 8px;
}
.main-section .agency-items-list .agency-item .images {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4px;
}
.main-section .agency-items-list .agency-item .images a {
  display: flex;
}
.main-section .agency-items-list .agency-item .images a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.main-section .agency-items-list .agency-item .description {
  display: flex;
  flex-direction: column;
  grid-gap: 7.5px;
  font-size: calc(12px + var(--big-fs));
  line-height: 16px;
}
.main-section .agency-items-list .agency-item .description .f-line {
  display: grid;
  grid-gap: 4px;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 7.5px;
}
.main-section .agency-items-list .agency-item .description .f-line .showname {
  display: flex;
  justify-content: space-between;
}
.main-section .agency-items-list .agency-item .description .f-line .showname a {
  width: fit-content;
  font-size: calc(14px + var(--big-fs));
  line-height: 18px;
}
.main-section .agency-items-list .agency-item .description .f-line .showname .premium-label {
  position: relative;
  top: -4px;
  right: -4px;
}
.main-section .agency-items-list .agency-item .description .c-line {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 7.5px;
}
.main-section .agency-items-list .agency-item .description .c-line .c-item {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: solid 1px #f0f0f0;
  background-color: #ffffff;
  color: #d61f79;
  height: 22px;
}
.main-section .agency-items-list .agency-item .description .c-line .c-item:hover {
  border-color: #d61f79;
  background-color: #d61f79;
  color: #ffffff;
}
.main-section .agency-items-list .agency-item .description .about-me {
  color: #000000;
}
.main-section .agency-items-list .agency-item .description .about-me > div {
  -webkit-line-clamp: 9;
  font-size: calc(12px + var(--big-fs));
  line-height: 16px;
}
.main-section .agency-items-list .agency-item .description .about-me a {
  font-weight: bold;
  font-size: calc(12px + var(--big-fs));
  line-height: 16px;
}
.main-section .agency-items-list .agency-item:hover {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
}
@media (max-width: 1024px) {
  .main-section {
    grid-template-columns: 1fr;
  }
}
